<script setup lang="ts">
defineEmits(['close'])

const { t } = useI18n()

const { signOut } = useAuth()
const toaster = useToaster()
const { redirectToHome } = useRedirectToHome()

const logoutClicked = async () => {
  toaster.clearAll()
  toaster.show({
    title: 'Uitgelogd',
    message: `Tot ziens!`,
    color: 'success',
    icon: 'ph:user-circle-fill',
    closable: true,
  })

  await signOut()
  redirectToHome()
}
</script>

<template>
  <BaseButton
    shape="curved"
    @click="
      () => {
        $emit('close')
        logoutClicked()
      }
    "
  >
    {{ t('logout') }}
  </BaseButton>
</template>

<i18n lang="json">
{
  "en": {
    "logout": "Logout"
  },
  "nl": {
    "logout": "Uitloggen"
  }
}
</i18n>
